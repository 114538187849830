export const BG_COVER = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/cover_wG8OFNqlF.jpg?updatedAt=1702528065906`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/coverdalam_footer_tb9qxmiOL.jpg?updatedAt=1702528067203`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/Cover%20Kevin%20&%20Aileen_BCWNSEaht.jpg?updatedAt=1702528925917`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/desktop_5rljAWb94G.jpg?updatedAt=1702528067209`;

export const IMG_AYAT = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/coverdalam_footer_tb9qxmiOL.jpg?updatedAt=1702528067203`;
export const IMG_LOGO = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/loog_4hrVL5V1c.png?updatedAt=1702528456635`;

export const IMG_MAN = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/groom_FuZJoXt5G.jpg?updatedAt=1702528066324`;
export const IMG_GIRL = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/bride_RZgOgJ38M.jpg?updatedAt=1702528063522`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/gal%201_ddtV4hQNQ.jpg?updatedAt=1702528067046`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/gal%202_CP8o5lbz5.jpg?updatedAt=1702528066709`;
export const IMG_PHOTO_CONTENT = `https://ik.imagekit.io/masansgaming/Kevin%20Aileen/gal%20content_jmaovFOkB.jpg?updatedAt=1702528065931`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
